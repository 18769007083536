'use strict';
/* global PriceSpider */
var detailBase = require('base/product/detail');
var formValidation = require('base/components/formValidation');
var orgBase = require('org/product/base');

/**
 * Selects the variation buttons elements on the storefront
 *
 * @param {string} variationId - The ID of the variation which buttons should be selected
 * @param {boolean} active - Whether the button should be active
 * @returns {jQuery|undefined} The jQuery object wrapping the DOM elements of the button
 */
function selectVariationBtn(variationId, active) {
    var $variationBtns;

    if (active) {
        $variationBtns = $('.product-overview__bundle-card.active');
    } else {
        $variationBtns = $('.product-overview__bundle-card');
    }

    if (!variationId) {
        return $variationBtns;
    }

    var $specificVariationBtns = [];

    $variationBtns.each(function () {
        var $btn = $(this);

        if ($btn.parent().data('attr-id') === variationId) {
            $specificVariationBtns.push($btn);
        }
    });

    return $specificVariationBtns;
}

detailBase.availability = orgBase.availability;

detailBase.addToCart = orgBase.addToCart;

detailBase.showSpinner = function () {
    $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
        // $.spinner().start(); removing spinner per Arlo team
    });
};

detailBase.updateAddToCart = function () {
    $('body').on('product:updateAddToCart', function (e, response) {
        var enableAddToCart = response.product.readyToOrder && response.product.available;

        // update local add to cart (for sets)
        $('button.add-to-cart', response.$productContainer).attr('disabled', !enableAddToCart);
        $('button.add-to-cart', response.$productContainer).attr('data-pid', response.product.id);
        $('button#btn-open-notify', response.$productContainer).attr('data-pid', response.product.id);

        // update display for back in stock based on availability
        $('.cart-and-ipay', response.$productContainer).toggleClass('d-none', !enableAddToCart);
        $('.back-in-stock-btn', response.$productContainer).toggleClass('d-none', enableAddToCart || response.product.productType === 'master' || response.product.productType === 'bundle');

        // update gtm with new product data
        $('button.add-to-cart').attr('data-gtmdata', response.product.gtmDataObj);
        $('button#btn-open-notify').attr('data-gtmdata', response.product.gtmDataObj);

        var enable = $('.product-availability').toArray().every(function (item) {
            return $(item).data('available') && $(item).data('ready-to-order');
        });
        $('button.add-to-cart-global').attr('disabled', !enable);
        // price spider button
        $('.price-spider').each(function () {
            if (response.product.hideFindARetailer !== null && !response.product.hideFindARetailer) {
                $(this).replaceWith('<div class="ps-nsosellers disable price-spider" ps-button-label="Find A Retailer">Find A Retailer</div> ');
                $('.pdp-cta-actions').addClass('d-none');
                $('.price-spider').html('');
                $('.price-spider').removeClass('ps-nsosellers');
                $('.price-spider').removeClass('d-none');
                $('.price-spider').removeClass('disable').addClass('ps-widget');
                $('.price-spider').attr('ps-sku', response.product.id);
                PriceSpider.rebind();
            } else {
                $('.pdp-cta-actions').removeClass('d-none');
                $(this).replaceWith('<div class="ps-nsosellers disable price-spider d-none" ps-button-label="Find A Retailer">Find A Retailer </div> ');
            }
        });
        if (!enableAddToCart) {
            $('.price-spider').addClass('notifyme');
        }
    });
};

detailBase.updateAvailability = function () {
    $('body').on('product:updateAvailability', function (e, response) {
        $('div.availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available);

        $('.availability-msg', response.$productContainer)
            .empty().html(response.message);
        if (response.product.availability && response.product.availability.messages.length > 1) {
            $('.availability-msg').parents('.availability-messages').addClass('w-100');
        } else {
            $('.availability-msg').parents('.availability-messages').removeClass('w-100');
        }
        if ($('.global-availability').length) {
            var allAvailable = $('.product-availability').toArray()
                .every(function (item) {
                    return $(item).data('available');
                });

            var allReady = $('.product-availability').toArray()
                .every(function (item) {
                    return $(item).data('ready-to-order');
                });

            $('.global-availability')
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg').empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        }
    });
};

detailBase.toggleOne = function () {
    $('body').on('click', '.product-bundle-detail .bundle-item .description , .product-bundle-detail .bundle-item .details', function () {
        if ($(this).siblings().hasClass('active')) {
            $(this).siblings().removeClass('active');
        }
    });
};

detailBase.backInStock = function () {
    $('body').on('submit', '.back-in-stock-form', function (e) {
        e.preventDefault();
        var form = $(this);
        var url = form.attr('action');
        form.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    formValidation(form, data);
                } else {
                    $('.back-in-stock-body').empty().append('<p>' + data.receivedMsgBody + '</p>');
                    $('.send-email-btn').empty();
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
        return false;
    });
};

detailBase.updateProduct = function () {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        // If colors are unselected reset dropdown
        var isColorSelected = false;
        var isSizeSelected = false;
        var witbHTML = '';
        var optionalColor = $('input[name$="variationColorName"]').val();
        var colorExist = $('input[name$="colorExist"]').val();
        // eslint-disable-next-line
        var $techspecsList = $('.product-overview__features-list');

        if (optionalColor) {
            if (colorExist) {
                for (var xx = 0, yx = response.data.product.variationAttributes[0].values.length; xx < yx; xx++) {
                    if (response.data.product.variationAttributes[0].values[xx].selected) {
                        isColorSelected = true;
                    }
                }
                if (response.data.product.variationAttributes.length > 1) {
                    for (var ix = 0, jx = response.data.product.variationAttributes[1].values.length; ix < jx; ix++) {
                        if (response.data.product.variationAttributes[1].values[ix].selected) {
                            isSizeSelected = true;
                        }
                    }
                }

                if (!isColorSelected) {
                    $('.custom-select-pdp').children('option:first-child').prop('selected', true);
                    $('.custom-select-pdp').trigger('change.select2');
                    $('.custom-select-pdp').attr('disabled', true);
                }

                if (isColorSelected && !isSizeSelected) {
                    // Select the first kit
                    var $variationBtnsSize = selectVariationBtn('size', false);
                    var $variationBtnSizeActive = selectVariationBtn('size', true);

                    if ($variationBtnsSize.length > 0 && $variationBtnSizeActive.length <= 0) {
                        $('.product-overview__bundle-card').eq(0).trigger('click');
                    }
                }
            } else {
                for (var a = 0, b = response.data.product.variationAttributes[0].values.length; a < b; a++) {
                    if (response.data.product.variationAttributes[0].values[a].selected) {
                        isSizeSelected = true;
                    }
                }
                if (!isSizeSelected) {
                    $('.custom-select-pdp').children('option:first-child').prop('selected', true);
                    $('.custom-select-pdp').trigger('change.select2');
                }
            }
        } else {
            for (var x = 0, y = response.data.product.variationAttributes[0].values.length; x < y; x++) {
                if (response.data.product.variationAttributes[0].values[x].selected) {
                    isColorSelected = true;
                }
            }
            if (response.data.product.variationAttributes.length > 1) {
                for (var i = 0, j = response.data.product.variationAttributes[1].values.length; i < j; i++) {
                    if (response.data.product.variationAttributes[1].values[i].selected) {
                        isSizeSelected = true;
                    }
                }
            }

            if (!isColorSelected) {
                $('.custom-select-pdp').children('option:first-child').prop('selected', true);
                $('.custom-select-pdp').trigger('change.select2');
                $('.custom-select-pdp').attr('disabled', true);
            }
            if (isColorSelected && !isSizeSelected) {
                $('.custom-select-pdp').children('option:first-child').prop('selected', true);
                $('.custom-select-pdp').trigger('change.select2');
            }
        }

        // Update product name
        var stickyHeaderName = response.data.product.topLine || response.data.product.displayName || response.data.product.productName;

        response.container.find('.product-name').html(response.data.product.displayName ? response.data.product.displayName : response.data.product.productName);
        response.container.find('.product-nav__product-name h5').html(stickyHeaderName);
        response.container.find('.product-overview__product-name h1').html(response.data.product.topName ? response.data.product.topName : response.data.product.displayName);
        response.container.find('.product-overview__product-name .product-type').html(response.data.product.bottomName ? response.data.product.bottomName : '');
        response.container.find('.product-overview__description').html(response.data.product.shortDescription);
        $('.product-overview__gallery-slides').slick('unslick');
        $('.product-overview__gallery-thumbnail').slick('unslick');
        $('.product-overview__gallery-slides').empty();
        $('.product-overview__gallery-thumbnail').empty();
        $('.product-overview__gallery-slides').html(orgBase.updateMainImages(response.data.product));
        $('.product-overview__gallery-thumbnail').html(orgBase.updateThumbnailImages(response.data.product));
        var thumbnailSlidesToShow = $('.product-overview__gallery-slide.thumbnail-slide').length;
        $('.product-overview__gallery-thumbnail').slick({
            asNavFor: '[data-product-media-slider]',
            centerMode: false,
            slidesToShow: thumbnailSlidesToShow,
            infinite: true,
            vertical: true,
            draggable: false,
            arrows: false,
            dots: false,
            focusOnSelect: true,
            slidesToScroll: 1,
            rows: 0
        });
        $('.product-overview__gallery-slides').slick({
            asNavFor: '[data-product-thumbnail-slider]',
            centerMode: false,
            variableWidth: false,
            infinite: true,
            draggable: false,
            arrows: true,
            dots: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            rows: 0,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                }
            ]
        });

        // change tech specs
        var techSpecsCount = response.data.product.techspecs.length;
        var $techSpecsString = '';
        var counter = 0;
        var techspec;
        var q;
        var w;
        // eslint-disable-next-line
        $techspecsList.html('');
        for (q = 0, w = response.data.product.techspecs.length; q < w; q++) {
            techspec = response.data.product.techspecs[q];
            if (q % 4 === 0) {
                if (counter === 0) {
                    counter++;
                }
                $techSpecsString += '<ul>';
            }
            $techSpecsString += '<li>' + techspec + '</li>';
            if (counter % 4 === 0 || counter === techSpecsCount) {
                $techSpecsString += '</ul>';
            }
            counter++;
        }
        // eslint-disable-next-line
        $techspecsList.html($techSpecsString);
        // response.container.find('.product-overview__features-list').html($techspecsList);
        // end change tech specs
        // Update back in stock HTML
        if (!response.data.product.available && response.data.product.backinstockbtn && response.data.product.backinstockmodal) {
            response.container.find('.back-in-stock-btn').html(response.data.product.backinstockbtn);
            response.container.find('.back-in-stock-modal').html(response.data.product.backinstockmodal);
        }
        // eslint-disable-next-line
        var headerSelectKitBtn = $('#master_select_kit_btn');
        // eslint-disable-next-line
        if (headerSelectKitBtn && response.data.product.variationAttributes != null) {
            if (response.data.product.readyToOrder) {
                // eslint-disable-next-line
                $(headerSelectKitBtn).hide();
            } else {
                // eslint-disable-next-line
                $(headerSelectKitBtn).show();
            }
        }

        // add whats in the box
        $.ajax({
            url: response.container.find('#whats-in-the-box').attr('data-url') + '?pid=' + response.data.product.id,
            type: 'get',
            success: function (data) {
                witbHTML = data;
            },
            error: function () {
                witbHTML = response.container.find('#whats-in-the-box').html();
            },
            complete: function () {
                response.container.find('#whats-in-the-box').html(witbHTML);
            }
        });

        // end whats in the box

        $('#prdID').html(response.data.product.id ? 'Item No. ' + response.data.product.id : '');
    });
};

detailBase.updateAttributesAndDetails = function () {
    $('body').on('product:statusUpdate', function (e, data) {
        var messages = '';
        var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

        $productContainer.find('.description-and-detail .product-attributes')
            .empty()
            .html(data.attributesHtml);

        if (data.shortDescription) {
            $productContainer.find('.description-and-detail .description')
                .removeClass('hidden-xl-down');
            $productContainer.find('.description-and-detail .description .content')
                .empty()
                .html(data.shortDescription);
        } else {
            $productContainer.find('.description-and-detail .description')
                .addClass('hidden-xl-down');
        }

        if (data.longDescription) {
            $productContainer.find('.description-and-detail .details')
                .removeClass('hidden-xl-down');
            $productContainer.find('.description-and-detail .details .content')
                .empty()
                .html(data.longDescription);
        } else {
            $productContainer.find('.description-and-detail .details')
                .addClass('hidden-xl-down');
        }

        if (data.availability) {
            $('.add-to-cart span').html(data.availability.buttonlabel);
        }

        $('.preorder-backorder-date').empty();
        if (data.availability) {
            if (data.availability.messages && data.availability.inStockDate) {
                data.availability.messages.forEach(function (message) {
                    messages += '<span>' + message + '</span>';
                });
            }
        }

        if (data.price.productDiscountPercentage) {
            $('.js-save-amount').removeClass('d-none');
            $('.js-save-amount').html(data.price.productDiscountPercentage);
        } else {
            $('.js-save-amount').addClass('d-none');
        }
        $('.preorder-backorder-date').html(messages);
    });
};

detailBase.updateVideoonPDP = function () {
    $(document).on('click', '.js-trigger-video-modal', function (e) {
        e.preventDefault();
        $('body').addClass('modal-static');
        $('body').addClass('noscroll');
        var iframe = $(this).parent('.product-overview__gallery-slide').find('.iFrame-text').val();
        $('.video-modal-content').append(iframe);
        $('.close-video-modal').removeClass('d-none');
        $('.overlay').addClass('modal-open');
        var id = $(this).attr('data-youtube-id');
        var youTubeAttrs = '?rel=0&mute=1&autoplay=1';
        var src = '//www.youtube.com/embed/' + id + youTubeAttrs;
        $('.video-modal-content').find('.youtube').attr('src', src);
    });

    $('body').on('click', '.close-video-modal, .modal-static', function (e) {
        e.preventDefault();
        $('body').removeClass('noscroll');
        $('body').removeClass('modal-static');
        $('.video-modal-content').html('');
        $('.overlay').removeClass('modal-open');
        $('.close-video-modal').addClass('d-none');
    });
    $('body').keyup(function (e) {
        if (e.keyCode === 27) {
            $('body').removeClass('noscroll');
            $('body').removeClass('modal-static');
            $('.video-modal-content').html('');
            $('.overlay').removeClass('modal-open');
            $('.close-video-modal').addClass('d-none');
        }
    });
};

detailBase.selectAttributeSync = function () {
    $(document).on('change', 'select[class*="select-"]', function () {
        var selectedOption = $(this).find(':selected').data('attr-value');
        var selectedColor = $('.color-value.swatch-circle.selected').data('attr-value');
        var otherSelect = $('select[class*="select-"]').not($(this)).not('.quantity-select').find('option[data-attr-value="' + selectedOption + '"]')
        .parent();
        $(otherSelect).find(':selected').prop('selected', false);
        $(otherSelect).find('option[data-attr-value="' + selectedOption + '"]').prop('selected', true);
        $(otherSelect).trigger('change.select2');
        if ($(this).children('option:first-child').is(':selected') && $('.color-value.swatch-circle').hasClass('selected')) {
            $('.custom-select-variation, .custom-select-dropdown-bluegray, .custom-select-pdp').removeAttr('disabled');
        } else {
            $('.color-value.swatch-circle').removeClass('selected');
            $('.color-value.swatch-circle[data-attr-value=' + selectedColor + ']').addClass('selected');
        }
    });
};

detailBase.selectDropdownInit = function () {
    $('.custom-select-pdp').select2({
        dropdownCssClass: 'bluegray',
        minimumResultsForSearch: -1
    });
    $('.custom-select-variation').select2({
        dropdownCssClass: 'select-dropdown-pdp',
        minimumResultsForSearch: -1
    });
    var colorExist = $('input[name$="colorExist"]').val();
    if (colorExist) {
        if (!$('.custom-select-pdp').children('option:first-child').is(':selected') || $('.color-value.swatch-circle').hasClass('selected')) {
            $('.custom-select-variation, .custom-select-dropdown-bluegray, .custom-select-pdp').removeAttr('disabled');
        } else {
            $('.custom-select-variation, .custom-select-dropdown-bluegray, .custom-select-pdp').attr('disabled', true);
        }
    } else {
        $('.custom-select-variation, .custom-select-dropdown-bluegray, .custom-select-pdp').removeAttr('disabled');
    }
};

detailBase.toolTipEvent = function () {
    var menu = $('.dropdown-menu-area');
    if ($(window).width() < 1199) {
        $(document).on('click', '.dropdown-opener', function () {
            if (menu) {
                $(menu).toggleClass('show');
            }
            if ($(menu).hasClass('show')) {
                $('section.product-overview').css('overflow', 'visible');
            } else {
                $('section.product-overview').css('overflow', 'hidden');
            }
        });
    } else {
        $(document).on('mouseenter', '.dropdown-opener', function () {
            if (menu) {
                $(menu).show();
            }
        });
        $(document).on('mouseleave', '.dropdown-tooltip', function () {
            if (menu) {
                $(menu).hide();
            }
        });
    }
};

// show Banner Icon Play when DOM is loaded
detailBase.showBannerIconPlay = function () {
    $('.video-banner-icon-play').show();
};

// show attributes when DOM is loaded
detailBase.showAttributes = function () {
    $('.product-overview__attributes').show();
    detailBase.selectDropdownInit();
};

detailBase.showCarousel = function () {
    if ($('body').find('video#pdp-featured-video').length > 0) {
        $('video#pdp-featured-video').on('canplay', function () {
            $('.product-overview__gallery').css('visibility', 'visible');
        });
    } else {
        $('.product-overview__gallery').css('visibility', 'visible');
    }
};

detailBase.customEvents = function () {
    $(document).on('click', '.js-customize-cta', function (e) {
        e.preventDefault();
        window.location.href = $(this).attr('href');
    });
};

detailBase.includedProdyct = function () {
    $(document).on('click', '.js-bundle-list-btn', function () {
        var hiddenProducts = $('.product-overview__set-item:nth-child(n+4)');
        hiddenProducts.addClass('show');
        $(this).addClass('d-none');
        $('.js-hide-list-btn').removeClass('d-none');
    });

    $(document).on('click', '.js-hide-list-btn', function () {
        var hiddenProducts = $('.product-overview__set-item.show');
        hiddenProducts.removeClass('show');
        $(this).addClass('d-none');
        $('.js-bundle-list-btn').removeClass('d-none');
    });
};

detailBase.sliceText = function () {
    const $container = $('.js-read-more-container');
    const $text = $container.find('.js-has-read-more-button');

    if (!$text.length) {
        return;
    }

    const shortTextLength = +($text.data('short-text-length')) || 25;
    const $readMoreBtn = $container.find('.js-read-more-btn');
    const $readLessBtn = $container.find('.js-read-less-btn');

    const fullText = $text.html().trim();

    const fullTextWordLength = fullText.split(' ').length;

    /**
     * Returns a shortened version of the text with HTML markup.
     *
     * @function getShortenedText
     * @param {string} text - The full text content (including HTML markup).
     * @param {number} maxLength - The maximum number of words to show in the short text before truncation.
     * @returns {string} The shortened version of the text with HTML markup.
     */
    function getShortenedText(text, maxLength) {
        const words = text.split(' ');
        if (words.length > maxLength) {
            return words.slice(0, maxLength).join(' ') + '...';
        }
        return text;
    }

    const shortText = getShortenedText(fullText, shortTextLength);

    if (fullTextWordLength) {
        $container.removeClass('hide-content');
    }

    if (fullTextWordLength > shortTextLength) {
        $text.html(shortText);
        $readMoreBtn.removeClass('d-none');
    }

    $(document).on('click', '.js-read-more-btn', function () {
        $text.html(fullText).addClass('active');
        $(this).addClass('d-none');
        $readLessBtn.removeClass('d-none');
    });

    $(document).on('click', '.js-read-less-btn', function () {
        $text.html(shortText).removeClass('active');
        $(this).addClass('d-none');
        $readMoreBtn.removeClass('d-none');
    });
};

module.exports = $.extend(detailBase, exports);
